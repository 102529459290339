import React from "react";
import styles from "./ContactInfo.module.css";
import { FaFacebookF } from "react-icons/fa6";
import { RiInstagramFill, RiWhatsappFill } from "react-icons/ri";
export default function ContactInfo() {
  return (
    <div className={styles.wrapper}>
      <p>
        Ground Floor, Residency Road, Lambert Lane, Srinagar, Srinagar, Jammu
        and Kahmir, 190001{" "}
      </p>
      <p>Springs Mall , K.P Road Anantnag - Jammu & Kashmir - 192101 </p>
      <p>
        Email:{" "}
        <a href="mailto:contact@zealclothing.com">contact@zealclothing.com</a>
      </p>
      <p>
        Phone: <a href="tel:918899989579">91 8899 989 579</a>
      </p>
      <div className={styles.socials}>
        <a
          href="https://www.facebook.com/zealsrinagar"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/zealsrinagar/"
          target="_blank"
          rel="noreferrer"
        >
          <RiInstagramFill />
        </a>
        <a href="https://wa.me/916006395995" target="_blank" rel="noreferrer">
          <RiWhatsappFill />
        </a>
      </div>
    </div>
  );
}
